import Layout from "../../Layouts/Layout";
import React, { Fragment, useState } from "react";
import { Disclosure, Listbox, Menu, Transition } from "@headlessui/react";
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
  BriefcaseIcon,
  CalendarIcon,
  CheckCircleIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  CurrencyPoundIcon,
  EnvelopeIcon,
  ArrowRightCircleIcon,
  MagnifyingGlassIcon,
  MapPinIcon,
  PencilIcon,
  XCircleIcon,
  DocumentTextIcon,
} from "@heroicons/react/20/solid";
import moment from "moment";
import ApplyModal from "../../Components/Jobs/ApplyModal";
import { InformationCircleIcon } from "@heroicons/react/20/solid";

const JobShow = ({ job, user, seeker }) => {
  const [showModal, setShowModal] = useState(false);
  const [jobState, setJobState] = useState(job);
  const [jobApplied, setJobApplied] = useState(job.setJobApplied);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  console.log(job);

  return (
    <Layout>
      <a
        href={
          user.main_account_type == "recruiter"
            ? "/job_owners?page=1"
            : "/jobs?page=1"
        }
        className="hover:underline mb-3 text-gray-600 flex flew-row items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6 mr-2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
          />
        </svg>
        Back
      </a>

      {!seeker && (
        <div className="rounded-md bg-blue-50 p-4 my-3">
          <div className="flex">
            <div className="flex-shrink-0">
              <InformationCircleIcon
                className="h-5 w-5 text-blue-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="text-sm text-blue-700">
                Please complete your job seeker profile to apply for a job.
              </p>
              <p className="mt-3 text-sm md:ml-6 md:mt-0">
                <a
                  href="/profile?type=job_seeker"
                  target="_blank"
                  className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
                >
                  Complete Profile
                  <span aria-hidden="true"> &rarr;</span>
                </a>
              </p>
            </div>
          </div>
        </div>
      )}

      <header className="bg-gray-50 py-6 border-b border-orange-500">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
          <div className="min-w-0 flex-1">
            <h1 className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
              {jobState.title}
            </h1>
            <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-8">
              <div className="mt-2 flex items-center text-sm text-gray-500 capitalize">
                <BriefcaseIcon
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                {jobState.trades.length
                  ? jobState.trades[0].name
                  : "Unknown Trade"}
              </div>
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <MapPinIcon
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                {jobState.location && jobState.location.city}
              </div>
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <CurrencyPoundIcon
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                £{jobState.rate}{" "}
                <span className="capitalize ml-1 text-xs">
                  ({jobState.interval})
                </span>
              </div>
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <CalendarIcon
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                Starts {moment(jobState.start_date).format("LL")}
              </div>
              {jobState.dates_type && (
                <div className="mt-2 flex items-center text-sm text-gray-500">
                  <DocumentTextIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  {jobState.dates_type == "unknown"
                    ? "Unknown contract length"
                    : capitalizeFirstLetter(jobState.dates_type) + " contract"}
                </div>
              )}
            </div>
            <div className="mt-2.5">
              {jobState.status == "open" ? (
                <span className="inline-flex items-center rounded-full bg-green-500 px-3 py-0.5 text-sm font-medium text-white">
                  <svg
                    className="-ml-1 mr-1.5 h-2 w-2 text-green-200"
                    fill="currentColor"
                    viewBox="0 0 8 8"
                  >
                    <circle cx={4} cy={4} r={3} />
                  </svg>
                  Active Job
                </span>
              ) : null}
            </div>
          </div>
          <div className="mt-5 flex xl:mt-0 xl:ml-4">
            {jobState.job_owner.user_id == user.id ? (
              <span className="hidden sm:block">
                <a
                  href={"/jobs/" + jobState.id + "/edit"}
                  className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                >
                  <PencilIcon
                    className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  Edit
                </a>
              </span>
            ) : !seeker ? (
              <span className="block">
                <a
                  href="/profile?type=job_seeker"
                  target="_blank"
                  className="inline-flex cursor-pointer items-center rounded-md bg-orange-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-orange-400 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                >
                  Complete Profile
                </a>
              </span>
            ) : (
              <span className="ml-3 hidden xl:block">
                <button
                  onClick={() => setShowModal(!showModal)}
                  type="button"
                  disabled={jobApplied}
                  className="inline-flex items-center rounded-md bg-orange-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-orange-400 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                >
                  {jobApplied ? "Applied for" : "Apply"}
                  {!jobApplied && (
                    <ArrowRightCircleIcon
                      className="-mr-1 ml-2 h-5 w-5 text-white"
                      aria-hidden="true"
                    />
                  )}
                </button>
              </span>
            )}
          </div>
        </div>
      </header>
      <div className="grid grid-cols-12 py-10">
        <div className="col-span-12 lg:col-span-8 px-4 sm:px-6 lg:px-8 ">
          <div className="pb-5">
            <h3 className="text-lg font-medium leading-6 text-gray-800">
              Job Description
            </h3>
            <p className="mt-2 text-gray-500">{jobState.description}</p>
          </div>
          <div className="pb-6">
            <h3 className="text-lg font-medium leading-6 text-gray-800">
              Required Qualifications
            </h3>
            <ul className="list-disc mt-3 list-inside">
              {jobState.qualifications.map((q) => {
                return <li className="text-gray-500">{q.name}</li>;
              })}
            </ul>
          </div>
          {jobState.job_owner.user_id != user.id && (
            <div className="hidden lg:block pb-6">
              {!seeker ? (
                <span className="block">
                  <a
                    href="/profile?type=job_seeker"
                    target="_blank"
                    className="inline-flex cursor-pointer items-center rounded-md bg-orange-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-orange-400 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                  >
                    Complete Profile
                  </a>
                </span>
              ) : (
                <span className="block">
                  <button
                    onClick={() => setShowModal(!showModal)}
                    type="button"
                    disabled={jobApplied}
                    className="inline-flex items-center rounded-md bg-orange-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-orange-400 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                  >
                    {jobApplied ? "Applied for" : "Apply"}
                    {!jobApplied && (
                      <ArrowRightCircleIcon
                        className="-mr-1 ml-2 h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                    )}
                  </button>
                </span>
              )}
            </div>
          )}
        </div>
        <div className="col-span-12 lg:col-span-4 px-4 sm:px-6 lg:px-8 lg:border-l lg:border-gray-300">
          {/* <div className="pb-5">
            <h3 className="text-lg font-medium leading-6 text-gray-800">
              Additional Requirements
            </h3>
            <div className="mt-3">
              <div className="flex flex-row items-center mb-3">
                {jobState.needs_own_transport ? (
                  <CheckCircleIcon
                    className="h-6 w-6 text-green-500 mr-2"
                    aria-hidden="true"
                  />
                ) : (
                  <XCircleIcon
                    className="h-6 w-6 text-red-500 mr-2"
                    aria-hidden="true"
                  />
                )}
                <p className="text-gray-500">Driving Licence</p>
              </div>
              <div className="flex flex-row items-center mb-3">
                {jobState.needs_personal_safety_equipment ? (
                  <CheckCircleIcon
                    className="h-6 w-6 text-green-500 mr-2"
                    aria-hidden="true"
                  />
                ) : (
                  <XCircleIcon
                    className="h-6 w-6 text-red-500 mr-2"
                    aria-hidden="true"
                  />
                )}
                <p className="text-gray-500">Personal Safety Equipment</p>
              </div>
              <div className="flex flex-row items-center mb-3">
                {jobState.needs_personal_tools_and_equipment ? (
                  <CheckCircleIcon
                    className="h-6 w-6 text-green-500 mr-2"
                    aria-hidden="true"
                  />
                ) : (
                  <XCircleIcon
                    className="h-6 w-6 text-red-500 mr-2"
                    aria-hidden="true"
                  />
                )}
                <p className="text-gray-500">Personal Tools</p>
              </div>
            </div>
            <div className="block lg:hidden pb-6 mt-5">
              {jobState.job_owner.user_id != user.id && (
                <span className="block">
                  <button
                    onClick={() => setShowModal(!showModal)}
                    type="button"
                    disabled={jobApplied}
                    className="inline-flex w-full justify-center items-center rounded-md bg-orange-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-orange-400 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                  >
                    {jobApplied ? "Applied for" : "Apply"}
                    <ArrowRightCircleIcon
                      className="-mr-1 ml-2 h-5 w-5 text-white"
                      aria-hidden="true"
                    />
                  </button>
                </span>
              )}
            </div>
          </div> */}
        </div>
      </div>
      <ApplyModal
        show_modal={showModal}
        onJobChange={() => setJobApplied(true)}
        job={job}
      />
    </Layout>
  );
};

// JobShow.layout = (page) => <Layout children={page} />;

export default JobShow;
