import Layout from "../../Layouts/Layout";
import React, { Fragment, useState } from "react";
import { Disclosure, Listbox, Menu, Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  MapPinIcon,
  PencilIcon,
  XCircleIcon,
  DocumentTextIcon,
  PhoneIcon,
} from "@heroicons/react/20/solid";
import moment from "moment";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import Digs from "./Digs";

const urlGenration = (encodedUrl) => {
  const decodedURL = decodeURIComponent(encodedUrl);
  const substrings = decodedURL.split("https://");
  const count =
    substrings[0].length === 0 ? substrings.length - 1 : substrings.length;
  var newURL = decodedURL;
  if (count >= 2) {
    newURL = newURL.replace(
      "https://advertapproval.s3.eu-central-1.amazonaws.com/uploads/",
      ""
    );
    newURL = newURL.replace(
      "https://thetradesmanapp.s3.eu-west-1.amazonaws.com/uploads/",
      ""
    );
    newURL = newURL.replace(
      "https://thetradesmanapp.s3.amazonaws.com/uploads/",
      ""
    );
  }
  return newURL;
};

const ShowDig = ({ accommodation, user }) => {
  const [showModal, setShowModal] = useState(false);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <Layout>
      <a
        href="/accommodation?page=1"
        className="hover:underline mb-3 text-gray-600 flex flew-row items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6 mr-2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
          />
        </svg>
        Back
      </a>
      <Swiper
        className="mySwiper"
        modules={[Autoplay]}
        spaceBetween={5}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
      >
        {accommodation.dig_images.length ? (
          accommodation.dig_images.map((dig) => (
            <SwiperSlide key={dig.id}>
              <img
                src={urlGenration(dig.image.url)}
                className="h-64 w-full object-cover"
              />
            </SwiperSlide>
          ))
        ) : (
          <SwiperSlide>
            <img
              src="https://www.propertypriceadvice.co.uk/wp-content/uploads/2019/02/house-red-features-home-insurance.jpg"
              className="h-64 w-full object-cover"
            />
          </SwiperSlide>
        )}
      </Swiper>
      <header className="bg-gray-50 py-6 border-b border-orange-500">
        <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
          <div className="min-w-0 flex-1">
            <h1 className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
              {accommodation.name}
            </h1>
            <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-8">
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <MapPinIcon
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                {accommodation.post_code}
              </div>
              {/* <div className="mt-2 flex items-center text-sm text-gray-500">
                <CurrencyPoundIcon
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                £{accommodation.rent}
              </div> */}
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <PhoneIcon
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                {accommodation.phone_number}
              </div>
              <div className="mt-2 flex items-center text-sm text-gray-500">
                <DocumentTextIcon
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                Reference:{" "}
                {accommodation.reference_number !== ""
                  ? accommodation.reference_number
                  : " None " ?? " None "}
              </div>
            </div>
            <p className="mt-3 text-gray-700 text-sm">
              Call contact number provided for booking. Rates vary depending on
              the season
            </p>
            <div className="mt-2.5">
              {accommodation.status == "open" ? (
                <span className="inline-flex items-center rounded-full bg-green-500 px-3 py-0.5 text-sm font-medium text-white">
                  <svg
                    className="-ml-1 mr-1.5 h-2 w-2 text-green-200"
                    fill="currentColor"
                    viewBox="0 0 8 8"
                  >
                    <circle cx={4} cy={4} r={3} />
                  </svg>
                  Active accommodation
                </span>
              ) : null}
            </div>
          </div>
          <div className="mt-5 flex xl:mt-0 xl:ml-4">
            {accommodation.user_id == user.id ? (
              <span className="hidden sm:block">
                <a
                  href={"/accommodation/" + accommodation.id + "/edit"}
                  className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                >
                  <PencilIcon
                    className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  Edit
                </a>
              </span>
            ) : null}
          </div>
        </div>
      </header>
      <div className="grid grid-cols-12 py-10">
        <div className="col-span-12 lg:col-span-8 px-4 sm:px-6 lg:px-8 ">
          <div className="pb-5">
            <h3 className="text-lg font-medium leading-6 text-gray-800">
              Accommodation Description
            </h3>
            <p className="mt-2 text-gray-500">{accommodation.description}</p>
          </div>
          {/* <div className="pb-6">
            <h3 className="text-lg font-medium leading-6 text-gray-800">
              Required Qualifications
            </h3>
            <ul className="list-disc mt-3 list-inside">
              {accommodation.qualifications.map((q) => {
                return <li className="text-gray-500">{q.name}</li>;
              })}
            </ul>
          </div> */}
        </div>
        <div className="col-span-12 lg:col-span-4 px-4 sm:px-6 lg:px-8 lg:border-l lg:border-gray-300">
          <div className="pb-5">
            <h3 className="text-lg font-medium leading-6 text-gray-800">
              Additional Info
            </h3>
            <div className="mt-3">
              <div className="flex flex-row items-center mb-3">
                {accommodation.wifi ? (
                  <CheckCircleIcon
                    className="h-6 w-6 text-green-500 mr-2"
                    aria-hidden="true"
                  />
                ) : (
                  <XCircleIcon
                    className="h-6 w-6 text-red-500 mr-2"
                    aria-hidden="true"
                  />
                )}
                <p className="text-gray-500">WiFi</p>
              </div>
              <div className="flex flex-row items-center mb-3">
                {accommodation.bills ? (
                  <CheckCircleIcon
                    className="h-6 w-6 text-green-500 mr-2"
                    aria-hidden="true"
                  />
                ) : (
                  <XCircleIcon
                    className="h-6 w-6 text-red-500 mr-2"
                    aria-hidden="true"
                  />
                )}
                <p className="text-gray-500">Bills</p>
              </div>
              <div className="flex flex-row items-center mb-3">
                {accommodation.parking ? (
                  <CheckCircleIcon
                    className="h-6 w-6 text-green-500 mr-2"
                    aria-hidden="true"
                  />
                ) : (
                  <XCircleIcon
                    className="h-6 w-6 text-red-500 mr-2"
                    aria-hidden="true"
                  />
                )}
                <p className="text-gray-500">Parking</p>
              </div>
              <div className="flex flex-row items-center mb-3">
                {accommodation.kitchen ? (
                  <CheckCircleIcon
                    className="h-6 w-6 text-green-500 mr-2"
                    aria-hidden="true"
                  />
                ) : (
                  <XCircleIcon
                    className="h-6 w-6 text-red-500 mr-2"
                    aria-hidden="true"
                  />
                )}
                <p className="text-gray-500">Kitchen</p>
              </div>
              <div className="flex flex-row items-center mb-3">
                {accommodation.tv ? (
                  <CheckCircleIcon
                    className="h-6 w-6 text-green-500 mr-2"
                    aria-hidden="true"
                  />
                ) : (
                  <XCircleIcon
                    className="h-6 w-6 text-red-500 mr-2"
                    aria-hidden="true"
                  />
                )}
                <p className="text-gray-500">TV</p>
              </div>
              <div className="flex flex-row items-center mb-3">
                {accommodation.washing_machine ? (
                  <CheckCircleIcon
                    className="h-6 w-6 text-green-500 mr-2"
                    aria-hidden="true"
                  />
                ) : (
                  <XCircleIcon
                    className="h-6 w-6 text-red-500 mr-2"
                    aria-hidden="true"
                  />
                )}
                <p className="text-gray-500">Washing Machine</p>
              </div>
              <div className="flex flex-row items-center mb-3">
                {accommodation.garden ? (
                  <CheckCircleIcon
                    className="h-6 w-6 text-green-500 mr-2"
                    aria-hidden="true"
                  />
                ) : (
                  <XCircleIcon
                    className="h-6 w-6 text-red-500 mr-2"
                    aria-hidden="true"
                  />
                )}
                <p className="text-gray-500">Garden</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

// accommodationShow.layout = (page) => <Layout children={page} />;

export default ShowDig;
