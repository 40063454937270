import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import axios from "axios";
import Loader from "../../Components/Loader";

const AddCardForm = ({ setAddCardModal, setIsCardList, userId }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [addCard, setAddCard] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setloading] = useState(false);
  const handleAddCardSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setErrorMessage("");
    setloading(true);
    setAddCard(false);
    stripe
      .createToken(elements.getElement(CardElement))
      .then(function (result) {
        if (result?.token) {
          axios
            .post("/subscriptions/addcard", {
              token: result?.token?.id,
              userId: userId,
            })
            .then((res) => {
              if (res.status === 200) {
                if (res?.data?.status) {
                  window.location.reload();
                  setAddCardModal(false);
                  setAddCard(false);
                  setIsCardList((prev) => !prev);
                } else {
                  setAddCard(true);
                  setErrorMessage(res?.data?.message);
                }
              }
              setloading(false);
            })
            .catch((error) => {
              console.log("Error", error.response);
              setloading(false);
              setAddCard(true);
              setErrorMessage(
                error.response.data.message
                  ? error.response.data.message
                  : "An error occurred while adding the card. Please try again later."
              );
            });
        } else {
          setErrorMessage(
            result?.error?.message ||
              "An error occurred while processing the card."
          );
          setAddCard(true);
          setloading(false);
        }
      })
      .catch((error) => {
        setloading(false);
        setErrorMessage(
          "An error occurred while creating the token. Please try again later."
        );
        console.log("Error", error);
      });
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        fontFamily: "Arial, sans-serif",
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  return (
    <>
      <form onSubmit={handleAddCardSubmit} className="w-full">
        <div>
          <div className="text-left">
            <div className="flex justify-between items-center w-full p-2">
              <h3 className="text-xl font-medium leading-6 text-gray-900">
                Add Card
              </h3>
            </div>
            <div className="border-t border-gray-200 pb-4 pt-4">
              <div className="bg-slate-300 p-4 rounded-lg">
                <CardElement
                  options={CARD_ELEMENT_OPTIONS}
                  onChange={(e) => {
                    setErrorMessage("");
                    setAddCard(e?.complete);
                  }}
                />
              </div>
              <p className="text-red-500 mt-2">{errorMessage}</p>
            </div>
          </div>
        </div>
        <div className="sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <button
            type="submit"
            className={`${
              addCard ? "" : "cursor-not-allowed"
            } inline-flex items-center w-full justify-center rounded-md border border-transparent bg-orange-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm`}
            disabled={!addCard}
          >
            {loading ? <Loader /> : `ADD CARD`}
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
            onClick={() => {
              setAddCardModal(false);
              setIsCardList((prev) => !prev);
            }}
          >
            Cancel
          </button>
        </div>
      </form>
    </>
  );
};

export default AddCardForm;
