import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { Fragment, useRef, useState, useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { Dialog, Transition } from "@headlessui/react";
import Layout from "../../Layouts/Layout";
import { usePage } from "@inertiajs/inertia-react";
import AddCardForm from "../../Pages/Subscription/AddCardForm";
import PaymentOptions from "../../Pages/Subscription/PaymentOptions";
import Swal from "sweetalert2";
import axios from "axios";

export const Subscriptions = ({ plans, product_ids, cards }) => {
  const { user } = usePage().props;
  const [open, setOpen] = useState(false);
  const [paymentSuccessModal, setPaymentSuccessModal] = useState(false);
  const [trialSuccessModal, setTrialSuccessModal] = useState(false);
  const cancelButtonRef = useRef(null);
  const [stripePlans, setstripePlans] = useState(plans?.data ?? null);
  const [selectedPlanName, setSelectedPlanName] = useState("");
  const [selectedStripeProduct, setSelectedStripeProduct] = useState("");
  const [selectedInterval, setSelectedInterval] = useState("");
  const [selectedIntervalCount, setSelectedIntervalCount] = useState(0);
  const [priceId, setPriceId] = useState("");
  const [userId, setUserId] = useState(user?.id);
  const [addCardModal, setAddCardModal] = useState(false);
  const [subscriptionModal, setSubscriptionModal] = useState(false);
  const [isCardList, setIsCardList] = useState(false);
  const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);

  const handleSubmit = async (product_id, plan, months) => {
    console.log(stripePlans);
    console.log(product_id);
    console.log(plan);

    let price = stripePlans.find((s) => s.active && s.product == product_id);

    console.log("PRICE", price);

    axios
      .post("/subscriptions/createsubscription", {
        userId: userId,
        priceId: price.id,
        planName: plan,
      })
      .then((res) => {
        if (res?.status === 200 && res?.data?.status) {
          setOpen(false);
          Swal.fire({
            icon: "success",
            title: "Your subscription is now active",
            text: "Thank you!",
          }).then(() => {
            window.location.reload();
          });
          //   setloading(false);
          // stripe
          //   .confirmCardPayment(res?.data?.data, {
          //     payment_method: selectedCard?.card?.id,
          //   })
          //   .then((resPayment) => {
          //     if (resPayment?.paymentIntent?.id) {
          //       setOpen(false);
          //       setPaymentSuccessModal(true);
          //   setloading(false);

          //     } else {
          //       setErrorMessage(resPayment.message);
          //   setloading(false);
          //     }
          //   }).catch((error) => {
          //     setloading(false)
          //     setErrorMessage(error)
          //     Swal.fire('Error', error.message, 'error');
          //   });
        } else {
          //   setloading(false);
          Swal.fire("Error", res?.data?.message, "error");
          setOpen(false);
        }
      })
      .catch((error) => {
        // setloading(false);
        Swal.fire("Error", "Something went wrong!!", "error");
      });
  };

  const model = (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 w-full max-w-2xl sm:p-6">
                <div>
                  <div className="text-left">
                    <div className="flex justify-between items-center w-full p-2">
                      <h3 className="text-xl font-medium leading-6 text-gray-900">
                        Available Card
                      </h3>
                      <button
                        type="button"
                        onClick={() => setAddCardModal(true)}
                        className="inline-flex items-center rounded-md border border-transparent bg-orange-600 max-w-lg px-4 py-2 text-sm font-medium justify-center text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                      >
                        Add Card
                      </button>
                    </div>
                    <div className="border-t border-gray-200">
                      {/* {selectedInterval && selectedIntervalCount && priceId && ( */}
                      <>
                        <PaymentOptions
                          priceId={priceId}
                          userId={userId}
                          isCardList={isCardList}
                          selectedPlanName={selectedPlanName}
                          setOpen={setOpen}
                          setPaymentSuccessModal={setPaymentSuccessModal}
                        />
                      </>
                      {/* )} */}
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );

  const cardModal = (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 w-full max-w-2xl sm:p-6">
                <Elements
                  stripe={stripePromise}
                  setAddCardModal={setAddCardModal}
                  setIsCardList={setIsCardList}
                  userId={userId}
                >
                  <AddCardForm
                    setAddCardModal={setAddCardModal}
                    setIsCardList={setIsCardList}
                    userId={userId}
                  />
                </Elements>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );

  useEffect(() => {
    if (paymentSuccessModal) {
      Swal.fire({
        icon: "success",
        title: "Payment Successful",
        text: "Thank you for your purchase!",
      });
      setPaymentSuccessModal(false);
      setSelectedPlanName("");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }

    if (selectedInterval && selectedIntervalCount && selectedStripeProduct) {
      const filteredPlans = stripePlans.filter(
        (plan) =>
          plan.interval === selectedInterval &&
          plan.interval_count === selectedIntervalCount &&
          plan.product === selectedStripeProduct
      );
      if (filteredPlans.length > 0) {
        filteredPlans.forEach((plan) => {
          if (plan.active) {
            setPriceId(plan.id);
          }
        });
      }
    }
  }, [selectedInterval, selectedIntervalCount, paymentSuccessModal]);

  return (
    <>
      {open && model}
      {addCardModal && cardModal}

      <section className="bg-white">
        <div className="px-4 mx-auto max-w-screen-xl lg:px-6">
          <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">
              Subscription Plan
            </h2>
            {/* <p className="mb-5 font-light text-gray-500 sm:text-xl">we focus on markets where technology, innovation, and capital can unlock long-term value and drive economic growth.</p> */}
          </div>
          {user.main_account_type == "recruiter" ? (
            <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
              <div
                className={`flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg ${
                  user?.plan_name == "recruiter_plan"
                    ? `subscriptions-border`
                    : "border border-gray-300"
                } shadow xl:p-8`}
              >
                <div className="relative">
                  <h3 className="mb-4  text-2xl font-semibold relative inline-block">
                    Recruiter Plan
                    <span className="absolute  left-0  line bg-black  w-[80%]"></span>
                  </h3>
                </div>
                {(user.plan_status == "paid" ||
                  user.plan_status == "active" ||
                  user.plan_status == "trialing") &&
                user.subscription_id &&
                user.plan_name == "recruiter_plan" ? (
                  <p className="font-light text-gray-500 sm:text-lg">
                    <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                      {user.plan_status == "trialing"
                        ? `Trial days Activated`
                        : `Activated`}
                    </span>
                  </p>
                ) : null}
                <div className="flex justify-center items-end my-8">
                  <div className="font-extrabold flex items-start">
                    <span className="text-2xl">£</span>
                    <span className="text-64">60</span>
                  </div>
                  {/* <span className="text-gray-500 text-start font-semibold mb-2">
                    /Minimum of 6 months
                  </span> */}
                </div>
                <p className="font-light text-gray-500 sm:text-lg">
                  <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                    20% VAT Included
                  </span>
                </p>
                <ul role="list" className="mb-8 space-y-4 text-left">
                  {/* <li className="flex items-center space-x-3">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-green-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    {/* <span>3 month free trial</span> 
                  </li> */}
                  <li className="flex items-center space-x-3">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-green-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span>Post unlimited jobs</span>
                  </li>
                  <li className="flex items-center space-x-3">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-green-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span>Receive applicants instantly</span>
                  </li>
                  <li className="flex items-center space-x-3">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-green-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span>Up to 50 instant matches on each job post.</span>
                  </li>
                  <li className="flex items-center space-x-3">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-green-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span>
                      Access to applicants full job history and contact number
                    </span>
                  </li>
                </ul>
                {!user.plan_status && !user.subscription_id ? (
                  // <button
                  //   type="button"
                  //   onClick={() => {
                  //     setSelectedPlanName("recruiter_plan");
                  //     setSelectedStripeProduct(
                  //       product_ids?.recruiter_plan_product_id
                  //     );
                  //     setSelectedInterval("month");
                  //     setSelectedIntervalCount(1);
                  //     handleSubmit(
                  //       product_ids?.recruiter_plan_product_id,
                  //       "recruiter_plan",
                  //       1
                  //     );
                  //   }}
                  //   className={`${
                  //     (user.plan_status == "paid" ||
                  //       user.plan_status == "active" ||
                  //       user.plan_status == "trialing") &&
                  //     user.subscription_id &&
                  //     user.plan_name == "recruiter_plan"
                  //       ? "cursor-not-allowed"
                  //       : ""
                  //   } inline-flex items-center mt-3 rounded-md border border-transparent bg-orange-600 max-w-lg px-4 py-2 text-sm font-medium justify-center text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2`}
                  //   disabled={
                  //     (user.plan_status == "paid" ||
                  //       user.plan_status == "active" ||
                  //       user.plan_status == "trialing") &&
                  //     user.subscription_id &&
                  //     user.plan_name == "recruiter_plan"
                  //   }
                  // >
                  //   Start Subscription
                  // </button>
                  <form action="/create-checkout-session" method="POST">
                    {/* Add a hidden field with the lookup_key of your Price */}
                    <input
                      type="hidden"
                      name="lookup_key"
                      value="recruiter_key"
                    />
                    <button
                      id="checkout-and-portal-button"
                      type="submit"
                      className="inline-flex w-full items-center mt-3 rounded-md border border-transparent bg-orange-600 max-w-lg px-4 py-2 text-sm font-medium justify-center text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                    >
                      Subscribe
                    </button>
                  </form>
                ) : null}
                {user.plan_status == "cancelled" ? (
                  <button
                    type="button"
                    onClick={() => {
                      setSelectedPlanName("recruiter_plan");
                      setSelectedStripeProduct(
                        product_ids?.recruiter_plan_product_id
                      );
                      setSelectedInterval("month");
                      setSelectedIntervalCount(1);
                      handleSubmit(
                        product_ids?.recruiter_plan_product_id,
                        "recruiter_plan",
                        1
                      );
                    }}
                    className={`${
                      (user.plan_status == "paid" ||
                        user.plan_status == "active" ||
                        user.plan_status == "trialing") &&
                      user.subscription_id &&
                      user.plan_name == "recruiter_plan"
                        ? "cursor-not-allowed"
                        : ""
                    } inline-flex items-center mt-3 rounded-md border border-transparent bg-orange-600 max-w-lg px-4 py-2 text-sm font-medium justify-center text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2`}
                    disabled={
                      (user.plan_status == "paid" ||
                        user.plan_status == "active" ||
                        user.plan_status == "trialing") &&
                      user.subscription_id &&
                      user.plan_name == "recruiter_plan"
                    }
                  >
                    Restart Subscription
                  </button>
                ) : null}
                {user.plan_status == "active" &&
                user.subscription_id &&
                user.plan_name == "recruiter_plan" ? (
                  // <button
                  //   type="button"
                  //   onClick={() => {
                  //     setSelectedPlanName("recruiter_plan");
                  //     setSelectedStripeProduct(
                  //       product_ids?.recruiter_plan_product_id
                  //     );
                  //     setSelectedInterval("month");
                  //     setSelectedIntervalCount(3);
                  //     setOpen(true);
                  //     setIsCardList(true);
                  //   }}
                  //   className={`${
                  //     (user.plan_status == "paid" ||
                  //       user.plan_status == "active" ||
                  //       user.plan_status == "trialing") &&
                  //     user.subscription_id
                  //   } inline-flex items-center mt-3 rounded-md border border-transparent bg-orange-600 max-w-lg px-4 py-2 text-sm font-medium justify-center text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2`}
                  // >
                  //   Update Payment Method
                  // </button>
                  <form action="/create-portal-session" method="POST">
                    <button
                      id="checkout-and-portal-button"
                      type="submit"
                      className="inline-flex w-full items-center mt-3 rounded-md border border-transparent bg-orange-600 max-w-lg px-4 py-2 text-sm font-medium justify-center text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                    >
                      Manage your billing information
                    </button>
                  </form>
                ) : null}
              </div>
            </div>
          ) : null}
          {user.main_account_type == "accommodation" ? (
            <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
              <div
                className={`flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg ${
                  user?.plan_name == "accommodation_plan"
                    ? `subscriptions-border`
                    : "border border-gray-300"
                } shadow xl:p-8`}
              >
                <div className="relative">
                  <h3 className="mb-4  text-2xl font-semibold relative inline-block">
                    Accommodation Plan
                    <span className="absolute  left-0  line bg-black  w-[80%]"></span>
                  </h3>
                </div>
                {(user.plan_status == "paid" ||
                  user.plan_status == "active" ||
                  user.plan_status == "trialing") &&
                user.subscription_id &&
                user.plan_name == "accommodation_plan" ? (
                  <p className="font-light text-gray-500 sm:text-lg">
                    <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                      {user.plan_status == "trialing"
                        ? `Trail days Activated`
                        : `Activated`}
                    </span>
                  </p>
                ) : null}
                <div className="flex justify-center items-end my-8">
                  <div className="font-extrabold flex items-start">
                    <span className="text-2xl">£</span>
                    <span className="text-64">12</span>
                  </div>
                  <span className="text-gray-500 text-start font-semibold mb-2">
                    /Per Month
                  </span>
                </div>
                <p className="font-light text-gray-500 sm:text-lg">
                  <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                    20% VAT Included
                  </span>
                </p>
                <ul role="list" className="mb-8 space-y-4 text-left">
                  <li className="flex items-center space-x-3">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-green-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    {/* <span>3 month free trial</span> */}
                  </li>
                  <li className="flex items-center space-x-3">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-green-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span>Post unlimited properties</span>
                  </li>
                  <li className="flex items-center space-x-3">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-green-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span>Receive inquiries from traders</span>
                  </li>
                  <li className="flex items-center space-x-3">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-green-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span>Traders can reach you directly by phone</span>
                  </li>
                </ul>
                {/* {(user.plan_status == "paid" ||
                  user.plan_status == "active" ||
                  user.plan_status == "trialing") &&
                user.subscription_id &&
                user.plan_name !== "accommodation_plan" ? null : (
                  <button
                    type="button"
                    onClick={() => {
                      setSelectedPlanName("accommodation_plan");
                      setSelectedStripeProduct(
                        product_ids?.accommodation_plan_product_id
                      );
                      setSelectedInterval("month");
                      setSelectedIntervalCount(1);
                      setOpen(true);
                      setIsCardList(true);
                    }}
                    className={`${
                      (user.plan_status == "paid" ||
                        user.plan_status == "active" ||
                        user.plan_status == "trialing") &&
                      user.subscription_id &&
                      user.plan_name == "accommodation_plan"
                        ? "cursor-not-allowed"
                        : ""
                    } inline-flex items-center mt-3 rounded-md border border-transparent bg-orange-600 max-w-lg px-4 py-2 text-sm font-medium justify-center text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2`}
                    disabled={
                      (user.plan_status == "paid" ||
                        user.plan_status == "active" ||
                        user.plan_status == "trialing") &&
                      user.subscription_id &&
                      user.plan_name == "accommodation_plan"
                    }
                  >
                    {(user.plan_status == "paid" ||
                      user.plan_status == "active" ||
                      user.plan_status == "trialing") &&
                    user.subscription_id &&
                    user.plan_name == "accommodation_plan"
                      ? `Activated`
                      : `Add card and activate subscription`}
                  </button>
                )} */}
                {!user.plan_status && !user.subscription_id ? (
                  <button
                    type="button"
                    onClick={() => {
                      setSelectedPlanName("accommodation_plan");
                      setSelectedStripeProduct(
                        product_ids?.accommodation_plan_product_id
                      );
                      setSelectedInterval("month");
                      setSelectedIntervalCount(1);
                      handleSubmit(
                        product_ids?.recruiter_plan_product_id,
                        "accommodation_plan",
                        1
                      );
                    }}
                    className={`${
                      (user.plan_status == "paid" ||
                        user.plan_status == "active" ||
                        user.plan_status == "trialing") &&
                      user.subscription_id &&
                      user.plan_name == "accommodation_plan"
                        ? "cursor-not-allowed"
                        : ""
                    } inline-flex items-center mt-3 rounded-md border border-transparent bg-orange-600 max-w-lg px-4 py-2 text-sm font-medium justify-center text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2`}
                    disabled={
                      (user.plan_status == "paid" ||
                        user.plan_status == "active" ||
                        user.plan_status == "trialing") &&
                      user.subscription_id &&
                      user.plan_name == "accommodation_plan"
                    }
                  >
                    Begin 3 month trial
                  </button>
                ) : null}
                {(user.plan_status == "paid" ||
                  user.plan_status == "active" ||
                  user.plan_status == "trialing") &&
                user.subscription_id &&
                user.plan_name !== "accommodation_plan" ? null : (
                  <button
                    type="button"
                    onClick={() => {
                      setSelectedPlanName("accommodation_plan");
                      setSelectedStripeProduct(
                        product_ids?.accommodation_plan_product_id
                      );
                      setSelectedInterval("month");
                      setSelectedIntervalCount(3);
                      setOpen(true);
                      setIsCardList(true);
                    }}
                    className={`${
                      (user.plan_status == "paid" ||
                        user.plan_status == "active" ||
                        user.plan_status == "trialing") &&
                      user.subscription_id
                    } inline-flex items-center mt-3 rounded-md border border-transparent bg-orange-600 max-w-lg px-4 py-2 text-sm font-medium justify-center text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2`}
                  >
                    Update Payment Method
                  </button>
                )}
              </div>
            </div>
          ) : null}
          {user.main_account_type == "training" ? (
            <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
              <div
                className={`flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg ${
                  user?.plan_name == "training_provider_plan"
                    ? `subscriptions-border`
                    : "border border-gray-300"
                } shadow xl:p-8`}
              >
                <div className="relative">
                  <h3 className="mb-4  text-2xl font-semibold relative inline-block">
                    Training provider Plan
                    <span className="absolute  left-0  line bg-black  w-[80%]"></span>
                  </h3>
                </div>
                {(user.plan_status == "paid" ||
                  user.plan_status == "active" ||
                  user.plan_status == "trialing") &&
                user.subscription_id &&
                user.plan_name == "training_provider_plan" ? (
                  <p className="font-light text-gray-500 sm:text-lg">
                    <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                      {user.plan_status == "trialing"
                        ? `Trail days Activated`
                        : `Activated`}
                    </span>
                  </p>
                ) : null}
                <div className="flex justify-center items-end my-8">
                  <div className="font-extrabold flex items-start">
                    <span className="text-2xl">£</span>
                    <span className="text-64">60</span>
                  </div>
                  {/* <span className="text-gray-500 text-start font-semibold mb-2">
                    /Minimum of 6 months
                  </span> */}
                </div>
                <p className="font-light text-gray-500 sm:text-lg">
                  <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                    20% VAT Included
                  </span>
                </p>
                <ul role="list" className="mb-8 space-y-4 text-left">
                  <li className="flex items-center space-x-3">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-green-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span>1 month free trial</span>
                  </li>
                  <li className="flex items-center space-x-3">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-green-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span>Profiles will appear on our visual map</span>
                  </li>
                  <li className="flex items-center space-x-3">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-green-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span>Receive inquiries from traders</span>
                  </li>
                  <li className="flex items-center space-x-3">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-green-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span>Traders can reach you directly by phone</span>
                  </li>
                </ul>

                {!user.plan_status && !user.subscription_id ? (
                  <button
                    type="button"
                    onClick={() => {
                      setSelectedPlanName("training_provider_plan");
                      setSelectedStripeProduct(
                        product_ids?.training_provider_plan_product_id
                      );
                      setSelectedInterval("month");
                      setSelectedIntervalCount(1);
                      handleSubmit(
                        product_ids?.training_provider_plan_product_id,
                        "training_provider_plan",
                        1
                      );
                    }}
                    className={`${
                      (user.plan_status == "paid" ||
                        user.plan_status == "active" ||
                        user.plan_status == "trialing") &&
                      user.subscription_id &&
                      user.plan_name == "training_provider_plan"
                        ? "cursor-not-allowed"
                        : ""
                    } inline-flex items-center mt-3 rounded-md border border-transparent bg-orange-600 max-w-lg px-4 py-2 text-sm font-medium justify-center text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2`}
                    disabled={
                      (user.plan_status == "paid" ||
                        user.plan_status == "active" ||
                        user.plan_status == "trialing") &&
                      user.subscription_id &&
                      user.plan_name == "training_provider_plan"
                    }
                  >
                    Begin 3 month trial
                  </button>
                ) : null}
                {(user.plan_status == "paid" ||
                  user.plan_status == "active" ||
                  user.plan_status == "trialing") &&
                user.subscription_id &&
                user.plan_name !== "training_provider_plan" ? null : (
                  <button
                    type="button"
                    onClick={() => {
                      setSelectedPlanName("training_provider_plan");
                      setSelectedStripeProduct(
                        product_ids?.training_provider_plan_product_id
                      );
                      setSelectedInterval("month");
                      setSelectedIntervalCount(3);
                      setOpen(true);
                      setIsCardList(true);
                    }}
                    className={`${
                      (user.plan_status == "paid" ||
                        user.plan_status == "active" ||
                        user.plan_status == "trialing") &&
                      user.subscription_id
                    } inline-flex items-center mt-3 rounded-md border border-transparent bg-orange-600 max-w-lg px-4 py-2 text-sm font-medium justify-center text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2`}
                  >
                    Update Payment Method
                  </button>
                )}
              </div>
            </div>
          ) : null}
        </div>
      </section>
    </>
  );
};

Subscriptions.layout = (page) => <Layout children={page} />;

export default Subscriptions;
